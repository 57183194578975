@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

* {
  font-family: 'Source Code Pro', monospace;
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #0B2434;
}

main {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.navigation {
  height: 100px;
  width: 100%;
  background: #8660FF;
  color: white;
}

.navigation-title {
  margin: 0;
  padding: 30px;
  text-align: center;
}

.instructions {
  text-align: center;
  color: white;
  padding: 30px;
}

.counter {
  padding: 30px;
  text-align: center;
  color: white;
}

.dice-container {
  display: grid;
  grid-template: auto auto / repeat(5, 1fr);
  gap: 20px;
  margin: 75px;
}

.die-face {
  height: 50px;
  width: 50px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.die-num {
  font-size: 2rem;
}

.roll-dice {
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 6px;
  background: #8660FF;
  font-size: 1.2rem;
  cursor: pointer;
  color: white;
}

.roll-dice:focus {
  outline: none;
}

.roll-dice:active {
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}